<template>
    <div class="weather-forecast">
        <div class="top-space-to-header">
            <div class="container weather-forecast-date" v-for="weatherForecastDate in weatherForecastDays" :key="weatherForecastDate.value">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="weather-forecast-date-heading day-of-week">{{ weatherForecastDate.dayOfWeek}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="weather-forecast-date-heading"><span class="date">{{ weatherForecastDate.date }} </span><span class="month">{{ weatherForecastDate.month }}</span></div>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Tid</th>
                            <th>Temperatur °C</th>
                            <th>Nederbörd mm</th>
                            <th>Vind m/s</th>
                            <th class="air-pressure">Lufttryck hPa</th>
                            <th class="relative-humidity">Luftfuktighet %</th>
                        </tr>
                    </thead>
                    <tbody v-for="weatherForecastTime in weatherForecastDate.weatherForecastTimes" :key="weatherForecastTime.value">
                        <tr>
                            <td>{{ weatherForecastTime.time }}</td>
                            <td>{{ weatherForecastTime.temperature }}</td>
                            <td>{{ weatherForecastTime.precipitation }}</td>
                            <td>{{ weatherForecastTime.windSpeed }}</td>
                            <td class="air-pressure">{{ weatherForecastTime.airPressure }}</td>
                            <td class="relative-humidity">{{ weatherForecastTime.relativeHumidity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import "../../src/styles/_layout.scss";
    import "../../src/styles/_weather-forecast.scss";

    const axios = require("axios");

    export default {
        name: "WeatherForecast",
        data: function () {
            return {
                weatherForecastDays: [],
                //baseUrl: axios.defaults.baseURL,
                baseUrl: process.env.VUE_APP_WEB_API
            };
        },

        methods: {
            getWeatherForecast: function () {
                var self = this;
                axios
                    .get(self.baseUrl + "api/WeatherForecast/GetWeather")
                    .then(function (response) {
                        if (response.data.resultDTO.success == true) {
                            self.weatherForecastDays = response.data.weatherForecastDays;
                        }
                        else {
                            console.log(response.data.resultDTO.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.error = true;                     
                    });
            },
        },

        mounted() {
            this.getWeatherForecast();
        },
    };
</script>
